import React from "react";
import Helmet from "react-helmet";
import { Ui, locale, device } from "..";
import * as Components from "../components";
import { vkShare, fbShare } from "../share";
import isClient from "../isClient";
import "./index.css";

const onClickVk = () => {
    const { url, title, image } = locale.og.index;

    vkShare(url, title, image);
};

const onClickFb = () => {
    const { url, title, image } = locale.og.index;
    fbShare(url);
};

export default () => (
    <Components.OpenIdeasProvider>
        {({ openIdeas, isIndexAnimationStarted }) => (
            <>
                <Helmet>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                    />

                    <title>{locale.title}</title>
                    <meta name="description" content="Генератор конкурсов от digital-агентства Nimax." />
                    <meta property="og:title" content={locale.og.index.title} />
                    <meta property="og:description" content={locale.og.index.description} />
                    <meta property="og:type" content={locale.og.index.type} />
                    <meta property="og:url" content={locale.og.index.url} />
                    <meta property="og:image" content={locale.og.index.image} />

                    <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                    <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#72be91" />
                    <meta name="apple-mobile-web-app-title" content="Nimax" />
                    <meta name="application-name" content="Nimax" />
                    <meta name="msapplication-TileColor" content="#000000" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta property="og:site_name" content="Агентство Nimax" />
                    <link rel="manifest" href="/favicon/site.webmanifest" />
                    <script async defer src="https://connect.facebook.net/en_US/sdk.js" />
                </Helmet>

                <Ui.Orientation />

                <Components.LoaderProvider>
                    {({ visible }) => <Ui.Loader visible={visible} />}
                </Components.LoaderProvider>

                <Ui.Background fullScreen>
                    <Ui.Layout.Container>
                        <Ui.Layout.TopLeft zIndex={4}>
                            <Ui.Logo />
                        </Ui.Layout.TopLeft>

                        <Ui.Layout.TopLeftQuarter>
                            <div
                                style={{
                                    position: "absolute",
                                    top: `${(192 / 475) * 100}%`,
                                    left: `${(86 / 1017) * -100}%`
                                }}
                            >
                                <Ui.Animation type="5" hidden={isIndexAnimationStarted} />
                            </div>
                            <Ui.Title hidden={isIndexAnimationStarted} />
                            <div
                                style={{
                                    position: "absolute",
                                    top: `${(118 / 475) * 100}%`,
                                    right: `${(129 / 1017) * -100}%`
                                }}
                            >
                                <Ui.Animation type="2" hiddenOnPhone hidden={isIndexAnimationStarted} />
                            </div>
                        </Ui.Layout.TopLeftQuarter>

                        <Components.SizeContoller scaleFactor={1}>
                            {({ storeWrapperRef, storeScalableElemRef, scale }) => (
                                <>
                                    <Ui.Layout.BottomRightQuarter storeRef={storeWrapperRef}>
                                        <Ui.Description
                                            scale={scale}
                                            storeRef={storeScalableElemRef}
                                            hidden={isIndexAnimationStarted}
                                        />
                                    </Ui.Layout.BottomRightQuarter>

                                    <Ui.Layout.BottomRightQuarter storeRef={storeWrapperRef}>
                                        <Ui.Layout.Scalable scale={scale}>
                                            <Ui.Animation
                                                type="9"
                                                margin="-20rem 0px 0px 40rem"
                                                rotate="-32deg"
                                                hidden={isIndexAnimationStarted}
                                            />
                                        </Ui.Layout.Scalable>
                                    </Ui.Layout.BottomRightQuarter>

                                    <Ui.Layout.BottomRightQuarter storeRef={storeWrapperRef}>
                                        <Ui.Layout.Scalable scale={scale}>
                                            <Ui.Animation
                                                type="3"
                                                margin="20rem 0 0 28rem"
                                                hiddenOnPhone
                                                hidden={isIndexAnimationStarted}
                                            />
                                        </Ui.Layout.Scalable>
                                    </Ui.Layout.BottomRightQuarter>

                                    <Ui.Layout.BottomRightQuarter storeRef={storeWrapperRef}>
                                        <Ui.Layout.Scalable scale={scale}>
                                            <Ui.Icon
                                                type="stars"
                                                margin={(() => {
                                                    if (device.isDesktop) return "34rem 40rem 0 0";
                                                    if (device.isPhone) return "12rem 64rem 0 0";
                                                    return "31rem 37rem 0 0";
                                                })()}
                                                hidden={isIndexAnimationStarted}
                                            />
                                        </Ui.Layout.Scalable>
                                    </Ui.Layout.BottomRightQuarter>
                                </>
                            )}
                        </Components.SizeContoller>

                        <Ui.Layout.TopRightQuarter>
                            <Ui.Animation type="6" hidden={isIndexAnimationStarted} />
                        </Ui.Layout.TopRightQuarter>

                        <Ui.Layout.TopRightQuarter>
                            <Ui.Icon type="giveaway" margin="9rem 15rem 0 0" hidden={isIndexAnimationStarted} />
                        </Ui.Layout.TopRightQuarter>

                        <Ui.Layout.BottomLeft zIndex={4}>
                            <Components.IndexLikesProvider>
                                {({ indexLikes, likeIndex, indexShares }) => (
                                    <Ui.LikeRepost
                                        onClickLike={likeIndex}
                                        likes={indexLikes}
                                        shares={indexShares}
                                        onClickVk={onClickVk}
                                        onClickFb={onClickFb}
                                    />
                                )}
                            </Components.IndexLikesProvider>
                        </Ui.Layout.BottomLeft>

                        <Ui.Layout.BottomRight>
                            <Ui.Animation type="7" hidden={isIndexAnimationStarted} />
                        </Ui.Layout.BottomRight>

                        <Ui.Layout.CenterCenter padded>
                            <Ui.MoreIdeas large touchable onClick={openIdeas}>
                                <Ui.Text heavy italic block>
                                    {locale.moreIdeasLarge}
                                </Ui.Text>
                                <Ui.Text heavy italic block>
                                    {locale.moreIdeasLargeSubtitle}
                                </Ui.Text>
                            </Ui.MoreIdeas>
                        </Ui.Layout.CenterCenter>

                        <Ui.Layout.CenterCenter padded>
                            <Ui.Animation type="1" margin="19rem 29rem 0 0" hidden={isIndexAnimationStarted} />
                        </Ui.Layout.CenterCenter>
                        <Ui.Layout.CenterCenter padded>
                            <Ui.Animation
                                type="17"
                                margin="60rem 20rem 0 0"
                                hiddenOnPhone
                                hidden={isIndexAnimationStarted}
                            />
                        </Ui.Layout.CenterCenter>

                        <Ui.Layout.BottomLeftQuarter>
                            <Ui.Animation type="4" rotate="12deg" hidden={isIndexAnimationStarted} />
                        </Ui.Layout.BottomLeftQuarter>

                        {/* gatsby ssr bug fix */}
                        {isClient && <Components.Ideas isIndexPage isIndexAnimationStarted={isIndexAnimationStarted} />}
                    </Ui.Layout.Container>
                </Ui.Background>
            </>
        )}
    </Components.OpenIdeasProvider>
);
